export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3586858882283305',
  agentpwd : '3586@3305$',
  apikey : '99928748537149257b7c63b10544b41c',
  url : 'https://mekontso.afrikpay.com',
  id : 'mekontso.afrikpay.com',
  accepturl : 'https://mekontso.afrikpay.com/payment/web/success',
  cancelurl : 'https://mekontso.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.mekontso.com/print/",
  storage : 'home/oss'
};
